import React, { Fragment } from 'react';

import Layout from '../components/layout';
import SignUpForm from '../components/SignUp';
import { AppWrapper } from '../components/styled';

const SignUpPage = () => (
  <Fragment>
    <h1>SignUp</h1>
    <SignUpForm />
  </Fragment>
);

export default () => (
  <Layout>
    <AppWrapper>
      <SignUpPage />
    </AppWrapper>
  </Layout>
);
